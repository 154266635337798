<template>
	<v-navigation-drawer
			floating
			:stateless="$vuetify.breakpoint.width >= 600"
			color="transparent"
			v-model="drawer"
			app
			overlay-opacity="0.6"
			:width="240"
			right
	>
		<div class="fill-height cell">
			<div class="sub-title-2 d-flex px-3" style="height: 40px; align-items: center">{{ $tc('global.name.announcement', 2) }}</div>

			<v-divider class="mx-4" />

			<draggable
					v-if="base_ads.length !== 0"
					v-model="base_ads"
					class="pa-3"
					style="height: calc(100% - 126px); overflow: hidden auto"
					:sort="false"
					:group="{ name: 'catalog', pull: 'clone', put: false }"
			>
				<v-col v-for="item in base_ads" :key="item.id" style="cursor: grab" cols="auto" class="pt-0 px-0 pb-2">
					<div class="pa-2 rounded-lg text-center paragraph secondary white--text" v-html="item.name"></div>
				</v-col>
			</draggable>

			<v-divider class="mx-4" />

			<div class="pa-3 text-center">
				<div class="paragraph" v-if="base_ads.length !== 0">{{ $t('global.catalog.create_ads') }}</div>
				<div class="paragraph" v-else>{{ $t('global.catalog.no_ads') }}</div>
				<Button
						color="primary"
						classes="mt-2"
						:label="$tc('global.action.create', 1)"
						block
				        :click="() => $router.push('/dashboard/announcement')"
				/>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "SideBarRight",
		props: ['pevents'],
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			draggable: () => import('vuedraggable')
		},
		data(){
			return {
				base_ads: []
			}
		},
		computed: {
			drawer: {
				get(){
					return this.$store.getters['marketplace/drawer_ads']
				},
				set(val) {
					this.$store.commit('marketplace/CHANGE_DRAWER_ADS_VAL', val)
				}
			}
		},
		methods: {
			setUpHooks(){
				this.pevents.wait('ads', (ads) => {
					this.base_ads = ads
				})
			}
		},
		created() {
			this.setUpHooks()
		}
	}
</script>

<style scoped>

</style>